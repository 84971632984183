.tab {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  color: #000; /* Default tab color */
}

.tab.active {
  color: #ffffff;
  background-color: #3470ff;
  padding: 0.5rem 4rem;
  border-radius: 50px; /* Active tab color */
}

