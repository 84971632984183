.SignUp {
  background-color: #4bc9ff !important;
}

.main_top_bg {
  background: linear-gradient(180deg, #d7f2ff 0%, rgba(215, 242, 255, 0) 100%);
}

.WeightLossHeading {
  font-family: "Sora";
  font-size: 48px;
  font-weight: 700;
  line-height: 63px;
}

.weightlosstext {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;
}

.card_weight {
  height: 400px !important;
  width: 100% !important;
  border-radius: 30px !important;
  border: 1px solid #d6d8e8;
  height: 105%;
  padding: 5%;
}

.card_weight:hover {
  color: #ffffff;
  background: linear-gradient(337deg, #5c2ce0 4.97%, #4bc9ff 90.34%),
    linear-gradient(0deg, #d6d8e8, #d6d8e8);
}

.card_img {
  width: 190px !important;
  height: 190px;
  margin-left: 12%;
}

.card-title {
  font-family: " Sora";
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
}

.Weightloassrelationship {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0px;
  text-align: left;
}
.weightlosstext {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0px;
  text-align: left;
}

.evenly {
  border: 1px solid #d6d8e8;
  border-radius: 10px;
}

.the_relation {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 500;
  line-height: 35px;
}
.body_card {
  margin-top: 50% !important;
}
h5 {
  font-size: 24px;
  font-family: "Sora";
  font-weight: 600;
  line-height: 35px;
}
.card-text-p {
  font-size: 16px;
  font-family: "Inter";
  font-weight: 400;
  line-height: 28px;
}

@media (max-width: 580px) {
  .WeightLossHeading {
    font-family: "Sora";
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 50px !important;
  }

  .weightlosstext {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
  }
  .carousel_item {
    border-radius: 30px !important;
    border: 1px solid #d6d8e8;
    height: 105%;
    padding: 5%;
    height: 50vh;
  }

  .carousel_item:hover {
    color: #ffffff;
    background: linear-gradient(337deg, #5c2ce0 4.97%, #4bc9ff 90.34%),
      linear-gradient(0deg, #d6d8e8, #d6d8e8);
  }
  h5 {
    font-size: 24px;
    font-family: "Sora";
    font-weight: 600;
    line-height: 30px;
    color: #000000 !important;
  }
  .card-text-p {
    font-size: 18px;
    font-family: "Inter";
    font-weight: 400;
    line-height: 28px;
    color: #000000;
  }
  .card_img {
    width: 190px !important;
    height: 190px;
    margin-left: 20%;
  }
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -28px !important;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 33% !important;
    margin-bottom: 1rem;
    margin-left: 33% !important;
    list-style: none;
  }

  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px !important;
    height: 10px !important;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #3470ff;
    background-clip: padding-box;
    border: 1px solid #3470ff !important;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
    border-radius: 50%;
  }
}
