.footerscustomAccordian {
  .accordianItem {
    --bs-accordion-border-width: 0 !important;
  }
  .accordianHeader {
    --bs-accordion-btn-color: #ffff;
    --bs-accordion-active-color: #fff;
    --bs-accordion-active-bg: #000;
    --bs-accordion-border-width: 0;
    --bs-accordion-btn-bg: #000;
    --bs-accordion-border-radius: 0;
    --bs-accordion-btn-focus-border-color: #000;
    --bs-accordion-btn-focus-box-shadow: 0;
    --bs-accordion-btn-padding-x: 0;
    --bs-accordion-btn-padding-y: 0;
    // --bs-accordion-btn-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-up' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z'/%3E%3C/svg%3E");
    // --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-up' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z'/%3E%3C/svg%3E");
  }
  //   .accordion-button::after {
  //     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E") !important;
  //   }
}
