.getmore {
  font-family: "Sora";
  font-size: 36px !important;
  font-weight: 700 !important;
  line-height: 50px !important;
}

.relationship {
  font-family: "Inter" !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 36px !important;
  text-align: center;
}
.healthCard {
  width: 312px;
  height: 660px;
  border-radius: 24px;
  border: 1px solid #d6d8e8;
}

.healthCard:hover {
  color: #ffffff;
  background: linear-gradient(337deg, #5c2ce0 4.97%, #4bc9ff 90.34%),
    linear-gradient(0deg, #d6d8e8, #d6d8e8);
}

@media (max-width: 580px) {
  .getmore {
    font-family: "Sora";
    font-size: 36px;
    font-weight: 700;
    line-height: 50px;
    text-align: left;
  }

  .relationship {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
  }

  .carousel_item {
    border-radius: 30px !important;
    border: 1px solid #d6d8e8;
    height: 105%;
    padding: 5%;
    height: 50vh;
  }

  .carousel_item:hover {
    color: #ffffff;
    background: linear-gradient(337deg, #5c2ce0 4.97%, #4bc9ff 90.34%),
      linear-gradient(0deg, #d6d8e8, #d6d8e8);
  }

  h5 {
    font-size: 24px;
    font-family: "Sora";
    font-weight: 600;
    line-height: 30px;
    color: #000000 !important;
  }

  .card-text-p {
    font-size: 18px;
    font-family: "Inter";
    font-weight: 400;
    line-height: 28px;
    color: #000000;
  }

  .card_img {
    width: 190px !important;
    height: 190px;
    margin-left: 20%;
  }

  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -28px !important;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 33% !important;
    margin-bottom: 1rem;
    margin-left: 33% !important;
    list-style: none;
  }

  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px !important;
    height: 10px !important;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #3470ff;
    background-clip: padding-box;
    border: 1px solid #3470ff !important;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
    border-radius: 50%;
  }
  .the_relation {
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 50px !important;
  }
  .work_acc_img {
    width: 100%;
    margin-top: 10%;
  }
  .essence {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
  }
  .evenly {
    border: 1px solid #d6d8e8;
    border-radius: 10px;
    padding: 2%;
  }
}
