.PatientSubscriptionProgress {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 1rem 0;

  #progress {
    position: relative;
    width: 90%;
    max-width: 360px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #progress .active {
    background-color: #6f4bfd !important;
    border: 4px solid #baa8fe !important;
    height: 60px !important;
    width: 60px !important;
  }

  #progress::before {
    content: " ";
    background-color: rgb(216, 217, 231);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 4px;
    width: 100%;
    z-index: -1;
  }

  .progress {
    background-color: #6f4bfd !important;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 4px;
    z-index: -1;
    width: 0%;
    transition: 0.4s ease;
  }

  .step {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 54px;
    width: 54px;
    border-radius: 50%;
  }

  .step-completed {
    background-color: #6f4bfd !important;
  }

  .step-pending {
    background-color: white;
  }

  .non-active {
    border: 1px solid #333;
  }

  .width-50 {
    width: 50% !important;
  }

  .width-100 {
    width: 100% !important;
  }
}
