.faqscustomAccordian {
  .accordianItem {
    --bs-accordion-border-width: 0;
  }
  .accordianHeader {
    --bs-accordion-btn-color: #000000;
    --bs-accordion-active-color: #000000;
    --bs-accordion-active-bg: white;
    --bs-accordion-border-width: 0;
    --bs-accordion-bg: white;
    --bs-accordion-border-radius: 0;
    --bs-accordion-btn-focus-border-color: white;
    --bs-accordion-btn-focus-box-shadow: 0;
    --bs-accordion-btn-padding-x: 0;
    --bs-accordion-btn-padding-y: 10;
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E");
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-dash' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E");
  }
  .accordianBody {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }
}
