.main_top_bg {
    background: linear-gradient(180deg, #D7F2FF 0%, rgba(215, 242, 255, 0) 100%);
}

.getmore {
    font-size: 48px;
    font-weight: 700;
    line-height: 63px;
}

.relationship {
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
}
.brain{
    font-family: "Sora";
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 5%;
}
.heart{
    width: 80%;
    margin-left: 10%;
}