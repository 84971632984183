.main_top_bg {
  background: linear-gradient(180deg, #d7f2ff 0%, rgba(215, 242, 255, 0) 100%);
}

.workHeading {
  font-family: "Sora";
  font-size: 36px;
  font-weight: 700;
  line-height: 63px;
  letter-spacing: 0px;
  text-align: center;
}

.workingPara {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;
}

.WorkPageCard {
  width: 110%;
  height: 520px;
  border: 1px solid #d6d8e8;
  border-radius: 30px;
}

.work-title {
  font-family: "Sora";
  font-size: 28px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
}
.work-text {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
}
.card-img-top {
  width: 190px !important;
  height: 190px;
  margin-left: 18%;
}

.evenly {
  border: 1px solid #d6d8e8;
  border-radius: 10px;
}

.workLeader {
  font-family: Sora;
  font-size: 48px;
  font-weight: 700;
  line-height: 63px;
  letter-spacing: 0px;
  text-align: center;
}

/* .accordion-button {
  --bs-accordion-btn-bg: #fff !important;
  --bs-according-active-bg: #ebf1ff !important;
  --bs-according-active-color: #3470ff !important;
  --bs-accordion-btn-focus-border-color: none !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  outline: none !important;
} */

.leadership {
  margin-top: 5%;
  background: linear-gradient(180deg, #d7f2ff 0%, rgba(215, 242, 255, 0) 100%);
}

.evenly {
  border: 1px solid #d6d8e8;
  border-radius: 10px;
}

.essenceCard {
  border: 1px solid #d6d8e8;
}

.essence {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 42px;
  letter-spacing: 0px;
  text-align: left;
}

@media (max-width: 580px) {
  .workHeading {
    font-family: "Sora";
    font-size: 36px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0px;
    text-align: left;
  }

  .workingPara {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
  }

  .workLeader {
    font-family: "Sora";
    font-size: 24px;
    font-weight: 700;
    text-align: left;
 
  }

  .carousel_item {
    border-radius: 30px !important;
    border: 1px solid #d6d8e8;
    height: 105%;
    padding: 5%;
    height: 50vh;
  }

  .carousel_item:hover {
    color: #ffffff;
    background: linear-gradient(337deg, #5c2ce0 4.97%, #4bc9ff 90.34%),
      linear-gradient(0deg, #d6d8e8, #d6d8e8);
  }

  h5 {
    font-size: 24px;
    font-family: "Sora";
    font-weight: 600;
    line-height: 30px;
    color: #000000 !important;
  }

  .card-text-p {
    font-size: 18px;
    font-family: "Inter";
    font-weight: 400;
    line-height: 28px;
    color: #000000;
  }

  .card_img {
    width: 190px !important;
    height: 190px;
    margin-left: 20%;
  }

  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -28px !important;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 33% !important;
    margin-bottom: 1rem;
    margin-left: 33% !important;
    list-style: none;
  }

  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px !important;
    height: 10px !important;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #3470ff;
    background-clip: padding-box;
    border: 1px solid #3470ff !important;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
    border-radius: 50%;
  }

  .work_acc_img {
    width: 100%;
    margin-top: 10%;
  }
  .essence {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
  }
  .evenly {
    border: 1px solid #d6d8e8;
    border-radius: 10px;
    padding: 2%;
  }
}
