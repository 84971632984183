.main_bg {
  background: linear-gradient(180deg, #d7f2ff 0%, rgba(215, 242, 255, 0) 100%);
}

.translate-middle-y {
  margin-left: 15%;
  margin-top: -25%;
}

.every_good_day {
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: 0px;
  text-align: left;
}

.GoodDay {
  color: #3470ff;
}

.top-0 {
  position: absolute;
  padding-top: 56%;
  padding-left: 16%;
}

.image {
  margin-left: 40.6%;
}

.sleep_img {
  position: absolute;
  top: 21%;
  right: 110px;
}

.play_icon {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  color: #000 !important;
}

.play_img {
  margin-right: 3%;
}

.SingUp {
  background-color: #4bc9ff;
}

.sleep {
  background: linear-gradient(90deg, #5c2ce0 0%, #4bc9ff 69.79%);
  padding: 10px;
  color: #ffffff;
  border-radius: 8px;
}

.getstart {
  background-color: #3470ff;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  margin-right: 5%;
}

@media screen and (min-width: 600px) and (max-width: 1440px) {
  .trans {
    margin-top: -250px;
  }

  .translate-middle-y {
    margin-left: 5%;
    margin-top: -22%;
  }

  .every_good_day {
    font-family: "Inter";
    font-size: 60px;
    font-weight: 700;
    line-height: 72px;
    text-align: left;
  }

  span {
    color: #3470ff;
  }

  .image {
    margin-left: 34%;
    width: 900px;
  }

  .bed_img {
    width: 79%;
    margin-left: 23%;
    margin-top: 5%;
  }

  .every {
    position: relative;
  }

  /* .bottom-0 {
    position: absolute;
    margin-top: 50%;
    margin-left: 20%;
    width: 80%;
  } */

  .sleep_img {
    position: absolute;
    bottom: 0px;
    margin-left: 16%;
    top: 2%;
  }

  .play_icon {
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    color: #000 !important;
  }

  .play_img {
    margin-right: 3%;
  }

  .sleep {
    background: linear-gradient(90deg, #5c2ce0 0%, #4bc9ff 69.79%);
    padding: 10px;
    color: #ffffff;
    border-radius: 8px;
  }

  .getstart {
    background-color: #3470ff;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    margin-right: 5%;
  }
}

@media (max-width: 580px) {
  .every_good_day_sm {
    font-size: 36px !important;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0px;
    text-align: left;
  }
  .latest {
    font-size: 16px !important;
    font-weight: 400;
    line-height: 30px;
  }
  .getstart {
    font-size: 18px !important;
    font-weight: 600;
    line-height: 21px;
  }
  .vector_img {
    margin-top: -160%;
    margin-left: -30%;
    width: 140%;
  }
  .copy_right_1 {
    color: #ffffff;
  }
}
