.piller_card {
  width: 375px;
  height: 545px;
  background-color: #ffffff;
  border: 1px solid #d6d8e8;
  border-radius: 30px;
}

.piller_heading_text {
  font-family: "Poppins";
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
}
.piller_text {
  font-family: "Sora";
  font-size: 24px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
}

.piller_card_img {
  margin-left: 6.5%;
}

.getstart {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 21.7px;
}

@media (max-width: 580px) {
  .piller_card {
    width: 325px;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #d6d8e8;
    border-radius: 30px;
  }
  .piller_heading_text {
    font-family: "Poppins";
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 0px;
    text-align: left;
  }
  .piller_text {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: center;
  }
  .piller_card_img {
    margin-left: -1.5%;
  }

  .getstart_1 {
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 21.7px;
  }
}
